<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6>
            <v-card class="elevation-12">
              <v-toolbar dark color="success">
                <v-toolbar-title class="mx-auto">
                  {{ $t("RegisterCustomer") }}
                </v-toolbar-title>
              </v-toolbar>
              <v-stepper v-model="step" elevation="0">
                <v-stepper-header>
                  <v-stepper-step
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :complete="step > index"
                    :step="index + 1"
                    :editable="step > index"
                  >
                    {{ tab.title }}
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :step="index + 1"
                  >
                    <v-card-text>

                      <div style="margin:0;padding:0;">
                  <v-img
                    class="mx-auto"
                    style="width: 150px; height: 100px"
                    src="/assets/logo.jpeg"
                  ></v-img>
                </div>

                      <v-form v-if="step === index + 1">
                        <div
                          v-if="tab.title === $t('PrimaryData')"
                          class="my-0 py-0"
                          :class="{
                            'has-error': errors.has('primaryDataValidation'),
                          }"
                        >
                          <h4 class="mb-2 mt-5">
                            {{ $t("PrimaryData") }}
                            <span class="required">*</span>
                          </h4>

                          <v-row>
                            <v-col cols="6">
                              <v-text-field
                                dense
                                class="mb-2"
                                outlined
                                name="firstName"
                                :label="$t('FirstName')"
                                v-validate="'required'"
                                data-vv-scope="primaryDataValidation"
                                :data-vv-as="$t('FirstName')"
                                v-model="primaryData.firstName"
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                dense
                                class="mb-2"
                                outlined
                                name="lastName"
                                :label="$t('LastName')"
                                v-validate="'required'"
                                data-vv-scope="primaryDataValidation"
                                :data-vv-as="$t('LastName')"
                                v-model="primaryData.lastName"
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-text-field
                            dense
                            class="mb-2"
                            outlined
                            name="email"
                            :label="$t('Email')"
                            v-validate="'required|email'"
                            data-vv-scope="primaryDataValidation"
                            :data-vv-as="$t('Email')"
                            v-model="primaryData.email"
                            hide-details
                          ></v-text-field>
                          <el-date-picker
                          style="width:100%"
                            dense
                            class="mb-2"
                            outlined
                            name="birthday"
                            :placeholder="$t('Birthday')"
                            type="date"
                            v-validate="'required'"
                            data-vv-scope="primaryDataValidation"
                            :data-vv-as="$t('Birthday')"
                            v-model="primaryData.birthday"
                            hide-details
                          ></el-date-picker>
                          <v-select
                            dense
                            class="mb-2"
                            outlined
                            name="gender"
                            :label="$t('Gender')"
                            :items="genderOptions"
                            v-validate="'required'"
                            data-vv-scope="primaryDataValidation"
                            :data-vv-as="$t('Gender')"
                            v-model="primaryData.gender"
                            hide-details
                          ></v-select>
                          <v-text-field
                            dense
                            class="mb-2"
                            outlined
                            name="phone"
                            :label="$t('Phone')"
                            v-validate="'required'"
                            data-vv-scope="primaryDataValidation"
                            :data-vv-as="$t('Phone')"
                            v-model="primaryData.phone"
                            hide-details
                          ></v-text-field>
                        </div>

                        <div
                          v-if="tab.title === $t('ManagementInfo')"
                          class="py-0"
                          :class="{
                            'has-error': errors.has('managementInfoValidation'),
                          }"
                        >
                          <h4 class="mb-2 mt-5">
                            {{ $t("ManagementInfo") }}
                            <span class="required">*</span>
                          </h4>
                          <v-text-field
                            dense
                            class="mb-2"
                            outlined
                            name="height"
                            :label="$t('Height')"
                            v-validate="'required'"
                            data-vv-scope="managementInfoValidation"
                            :data-vv-as="$t('Height')"
                            v-model="managementInfo.height"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            dense
                            class="mb-2"
                            outlined
                            name="weight"
                            :label="$t('Weight')"
                            v-validate="'required'"
                            data-vv-scope="managementInfoValidation"
                            :data-vv-as="$t('Weight')"
                            v-model="managementInfo.weight"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            dense
                            class="mb-2"
                            outlined
                            name="adlat"
                            :label="$t('Adlat')"
                            v-validate="'required'"
                            data-vv-scope="managementInfoValidation"
                            :data-vv-as="$t('Adlat')"
                            v-model="managementInfo.adlat"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            dense
                            class="mb-2"
                            outlined
                            name="fluids"
                            :label="$t('Fluids')"
                            v-validate="'required'"
                            data-vv-scope="managementInfoValidation"
                            :data-vv-as="$t('Fluids')"
                            v-model="managementInfo.fluids"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            dense
                            class="mb-2"
                            outlined
                            name="goal"
                            :label="$t('Goal')"
                            v-validate="'required'"
                            data-vv-scope="managementInfoValidation"
                            :data-vv-as="$t('Goal')"
                            v-model="managementInfo.goal"
                            hide-details
                          ></v-text-field>
                        </div>

                        <div
                          v-if="tab.title === 'persondesired'"
                          class="py-0"
                          :class="{
                            'has-error': errors.has('personDesiredValidation'),
                          }"
                        >
                          <h4 class="mb-2 mt-5">
                            {{ $t("PersonDesired") }}
                            <span class="required">*</span>
                          </h4>
                          <v-text-field
                            dense
                            class="mb-2"
                            outlined
                            name="carb"
                            :label="$t('Carb')"
                            v-validate="'required'"
                            data-vv-scope="personDesiredValidation"
                            :data-vv-as="$t('Carb')"
                            v-model="personDesired.carb"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            dense
                            class="mb-2"
                            outlined
                            name="protein"
                            :label="$t('Protein')"
                            v-validate="'required'"
                            data-vv-scope="personDesiredValidation"
                            :data-vv-as="$t('Protein')"
                            v-model="personDesired.protein"
                            hide-details
                          ></v-text-field>
                          <v-textarea
                            dense
                            class="mb-2"
                            outlined
                            name="notes"
                            :label="$t('Notes')"
                            v-model="personDesired.notes"
                            hide-details
                          ></v-textarea>
                        </div>

                        <div
                          v-if="tab.title === 'deliveryinfo'"
                          class="py-0"
                          :class="{
                            'has-error': errors.has('deliveryInfoValidation'),
                          }"
                        >
                          <h4 class="mb-2 mt-5">
                            {{ $t("DeliveryInfo") }}
                            <span class="required">*</span>
                          </h4>
                          <v-text-field
                            dense
                            class="mb-2"
                            outlined
                            name="deliveryPeriod"
                            :label="$t('DeliveryPeriod')"
                            v-validate="'required'"
                            data-vv-scope="deliveryInfoValidation"
                            :data-vv-as="$t('DeliveryPeriod')"
                            v-model="deliveryInfo.deliveryPeriod"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            dense
                            class="mb-2"
                            outlined
                            name="deliveryMethod"
                            :label="$t('DeliveryMethod')"
                            v-validate="'required'"
                            data-vv-scope="deliveryInfoValidation"
                            :data-vv-as="$t('DeliveryMethod')"
                            v-model="deliveryInfo.deliveryMethod"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            dense
                            class="mb-2"
                            outlined
                            name="deliveryLocation"
                            :label="$t('DeliveryLocation')"
                            v-validate="'required'"
                            data-vv-scope="deliveryInfoValidation"
                            :data-vv-as="$t('DeliveryLocation')"
                            v-model="deliveryInfo.deliveryLocation"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            dense
                            class="mb-2"
                            outlined
                            name="deliveryCompany"
                            :label="$t('DeliveryCompany')"
                            v-validate="'required'"
                            data-vv-scope="deliveryInfoValidation"
                            :data-vv-as="$t('DeliveryCompany')"
                            v-model="deliveryInfo.deliveryCompany"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            dense
                            class="mb-2"
                            outlined
                            name="deliveryContact"
                            :label="$t('DeliveryContact')"
                            v-validate="'required'"
                            data-vv-scope="deliveryInfoValidation"
                            :data-vv-as="$t('DeliveryContact')"
                            v-model="deliveryInfo.deliveryContact"
                            hide-details
                          ></v-text-field>
                          <v-textarea
                            dense
                            class="mb-2"
                            outlined
                            name="deliveryNotes"
                            :label="$t('DeliveryNotes')"
                            v-model="deliveryInfo.deliveryNotes"
                            hide-details
                          ></v-textarea>
                          <v-text-field
                            dense
                            class="mb-2"
                            outlined
                            name="deliveryLocation"
                            :label="$t('DeliveryLocation')"
                            v-validate="'required'"
                            data-vv-scope="deliveryInfoValidation"
                            :data-vv-as="$t('DeliveryLocation')"
                            v-model="deliveryInfo.deliveryLocation"
                            hide-details
                          ></v-text-field>
                        </div>
                      </v-form>
                    </v-card-text>
                    <v-card-actions class="py-0 d-flex justify-center">
                      <v-btn
                        v-if="step !== 1"
                        class="mr-4"
                        small
                        outlined
                        color="info"
                        @click="prevStep"
                        >{{ $t("Previous") }}</v-btn
                      >
                      <v-btn
                        v-if="step !== tabs.length"
                        small
                        outlined
                        color="info"
                        @click="nextStep"
                        >{{ $t("Next") }}</v-btn
                      >
                    </v-card-actions>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    step: 1,
    tabs: [],
    primaryData: {
      firstName: "",
      lastName: "",
      email: "",
      birthday: "",
      gender: "",
      phone: "",
    },
    managementInfo: {
      height: "",
      weight: "",
      adlat: "",
      fluids: "",
      goal: "",
    },
    personDesired: {
      carb: "",
      protein: "",
      notes: "",
    },
    deliveryInfo: {
      deliveryPeriod: "",
      deliveryMethod: "",
      deliveryLocation: "",
      deliveryCompany: "",
      deliveryContact: "",
      deliveryNotes: "",
      deliveryLocation: "",
    },
    genderOptions: ["Male", "Female"],
    type: 1,
    typeList: [],
    object: {
      mobile: null,
      password: null,
    },
  }),
  methods: {
    nextStep() {
      this.step++;
    },
    prevStep() {
      this.step--;
    },
    setActive(type) {
      this.type = type;
    },
    login() {
      localStorage.setItem("customerMobileFirstLogin", this.object.mobile);
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("auth/loginCustomer", this.object)
              .then((result) => {
                this.notifySuccess("Login", result);
                window.location.href = "/customer/home";
                this.loginCustomerSuccess(result);
              });
          }
        } else {
          let result = {
            response: {
              data: {
                errors: this.$t("MissedData"),
              },
            },
          };
          this.notifyError("Login", result);
        }
      });
    },
  },
  created() {
    this.tabs= [
      { title: this.$t('PrimaryData') },
      { title: this.$t('ManagementInfo') },
      { title: "persondesired" },
      { title: "deliveryinfo" },
    ]
  },
  mounted() {
    this.typeList = [
      {
        id: 1,
        name: this.$t("TypeUser"),
      },
      {
        id: 2,
        name: this.$t("TypeDriver"),
      },
    ];
  },
};
</script>


<style>
.btn-gradient-1 {
  width: 100%;
  border-radius: 10px;
  padding: 15px;
  margin: 0;
  background: #fff;
  color: #000;
  border: 1px solid #000 !important;
}

svg path {
  fill: #000 !important;
}

.active-login {
  background: linear-gradient(to right, #4b6cb7, #182848);
  color: white;
}

.active-login svg path {
  fill: #fff !important;
}

.active-login .fa-check {
  display: inline-block;
}

.fa-check {
  display: none;
}
</style>